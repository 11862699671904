<script setup lang="ts">
/**
 * UiSlideover
 *
 * Standardise and simplify showing slideover content
 *
 * @see https://github.com/forged-com/forgd/pull/1719
 */
const props = withDefaults(defineProps<{
  id?: string
  title?: string
  description?: string
  content?: string
  size?: keyof typeof sizes
  overlay?: boolean
  isOpen?: boolean
}>(), {
  size: 'md',
  overlay: true,
})

const emit = defineEmits<{
  close: [_: void]
}>()

const sizes = {
  sm: 'max-w-[560px]',
  md: 'max-w-[730px]',
  lg: 'max-w-[1100px]',
}

const { model } = useAppSlideover(props.id, props.isOpen)

watch(() => props.isOpen, (val) => {
  model.value = val
})

function close() {
  model.value = false
  emit('close')
}

const ui = computed(() => {
  return defineUi('slideover', {
    base: 'z-10',
    width: sizes[props.size],
    shadow: 'shadow-2xl',
    overlay: {
      background: 'backdrop-blur-sm',
    },
  })
})

onMounted(() => {
  if (props.isOpen) {
    model.value = true
  }
})

onUnmounted(close)
</script>

<template>
  <USlideover v-model="model" data-ui="UiSlideover" :overlay :ui @close="close">
    <div class="flex flex-col flex-1 border-l border-forgd-bgd-600 bg-forgd-bgd-100 font-display overflow-y-scroll">
      <!-- header -->
      <div
        class="
        sticky top-0 z-50
        min-h-[70px] p-5 flex items-center justify-between shrink-0
        border-b border-forgd-bgd-600 bg-forgd-bgd-100
        "
      >
        <slot name="header">
          <div>
            <div class="text-sm font-semibold">
              {{ title || `Sidebar: ${id}` }}
            </div>
            <div v-if="description" class="text-sm text-gray-600">
              {{ description }}
            </div>
          </div>
        </slot>

        <!-- close button -->
        <UiButton
          size="sm"
          variant="ghost"
          icon="i-heroicons-x-mark"
          @click="close"
        />
      </div>

      <!-- body -->
      <div class="flex flex-col flex-grow gap-5 p-5">
        <slot :close="close" />
      </div>

      <!-- footer -->
      <div
        v-if="$slots.footer"
        class="
          sticky bottom-0 z-50
          min-h-[90px] px-5 flex items-center justify-between gap-4 flex-shrink-0
          border-t border-forgd-bgd-600 bg-forgd-bgd-100
          "
      >
        <slot name="footer" :close="close" />
      </div>
    </div>
  </USlideover>
</template>
