/**
 * Hash of slideover ids and states
 */
const state: Record<string, Ref<boolean>> = {}

/**
 * Composable and global state to keep track of slideovers
 *
 * This composable is mainly internal to UiSidebar and UiSidebarTrigger, but can be used manually.
 *
 * @examples
 *
 * // single slideover on page
 * const { open } = useAppSlideover() // name is optional
 * open()
 *
 * // single slideover on page
 * const { model } = useAppSlideover()
 * model.value = true
 *
 * // multiple slideovers on page
 * const { model: slideover1 } = useAppSlideover('how-it-works')
 * slideover1.value = true
 *
 * // multiple slideovers on page
 * const slideovers = useAppSlideover()
 * slideovers.open('how-it-works')
 *
 * @param id      An optional slideover id, defaults to 'default' for when only one slideover on the page
 * @param open    An optional flag to set the slideover to open, defaults to false
 */
export function useAppSlideover(id: string = 'default', open = false) {
  // variables
  let model: Ref<boolean>

  // initialize or reuse state
  if (!(id in state)) {
    model = ref(open)
    state[id] = model
  }
  else {
    model = state[id]
  }

  /**
   * Toggle a known slideover
   */
  function update(id: string, value: boolean) {
    if (id in state) {
      state[id].value = value
    }
    else {
      throw new Error(`Unknown slideover id "${id}"`)
    }
  }

  return {
    // state of all slideovers
    state,

    // state of passed slideover
    model,

    // open default or known slideover
    open: (_id: string) => update(_id ?? id, true),

    // close default or known slideover
    close: (_id: string) => update(_id ?? id, false),
  }
}
