<script setup lang="ts">
import type { AcademyLink } from '#academy-virtual/types'

export interface Props {
  isOpen?: boolean
  path: AcademyLink
  title?: string
}

const props = defineProps<Props>()

const emit = defineEmits<{
  close: [_: void]
}>()
const tracer = useTracer()
// send analytics for opening the slideover
watch(() => props.isOpen, (val) => {
  if (val) {
    tracer.custom('academy_slideover:open', { path: props.path })
  }
}, {
  immediate: true,
})

const showFooter = computed(() => {
  const path = props.path as string
  // exclude links to partials
  return path.startsWith('/') && !path.includes('/_')
})

function close() {
  emit('close')
}
</script>

<template>
  <UiSlideover
    :id="path as string"
    :title="title || 'Readings & Resources'"
    :is-open="isOpen"
    size="sm"
    :overlay="false"
    @close="close"
  >
    <!-- content -->
    <div class="prose prose-sm">
      <AcademyContent :path="path as string" />
    </div>
    <template
      v-if="showFooter"
      #footer
    >
      <UiButton
        variant="solid"
        color="black"
        class="w-full items-center justify-center h-[50px]"
        icon="i-heroicons-arrow-top-right-on-square"
        trailing
        :ui="{
          font: 'font-display font-semibold',
        }"
        :to="path"
      >
        Read on Forgd Academy
      </UiButton>
    </template>
  </UiSlideover>
</template>
